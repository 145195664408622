import React from "react"
import { Redirect } from "@reach/router"
import Layout from "../components/layout"

export default ({ data }) => {
  return (
    <Layout>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Redirect noThrow to={node.fields.slug} />
      ))}
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { index: { eq: 1 } } }) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
        }
      }
    }
  }
`
